.registro-main {
  text-align: center;
  background-size: cover;
  background-position: center;
  padding: 50px;
}

.registro-links {
  margin-bottom: 20px;
}

.registro-links a {
  margin: 10px;
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.registro-links a:hover {
  text-decoration: underline;
}
